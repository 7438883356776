<template>
  <Toast />
  <div class="header-dash d-flex justify-content-between align-items-start">
    <h1>Gestão financeira</h1>
    <!-- <i class="fas fa-info-circle" /> -->
    <ButtonGradient 
      :class="{
        disabled: featureDisabled,
      }" 
      :type-button="'historic'" 
      @click="goTo('historic')" 
    />
  </div>

  <div v-if="dashboardBalance && dashboardBalance.data" 
    class="row gap-3"
    :class="{
      disabled: featureDisabled,
    }"
  >
    <div class="row gap-3">
      <div class="col-lg">
        <InvestmentBox
          class="box-custom"
          :balance="dashboardBalance.data.investment.balance"
        />
      </div>

      <div class="col-lg">
        <QuickBox @modal-open="openModal" />
      </div>
    </div>
    <div class="row gap-3">
      <div class="col-lg">
        <div class="row gap-3">
          <TransactionBox
            :typetransaction="'credit'"
            :current-value="dashboardBalance.data.balance.credit"
            :goals-value="dashboardBalance.data.goals.credit"
            class="col-lg"
          />

          <TransactionBox
            :typetransaction="'debit'"
            :current-value="dashboardBalance.data.balance.debit"
            :debitMonth="dashboardBalance.data.balance.debit_month"
            :goals-value="dashboardBalance.data.goals.debit"
            class="col-lg"
          />

          <TransactionBox
            :typetransaction="'investment'"
            :current-value="dashboardBalance.data.balance.investment"
            :goals-value="dashboardBalance.data.goals.investment"
            class="col-lg"
          />
        </div>
      </div>
    </div>

    <div class="row gap-3">
      <div class="col-lg">
        <GoalsBox
          class="box-custom dashboard-card"
          :dashboard-balance="dashboardBalance"
          :goals-balance="dashboardGoalsBalance"
        />
      </div>
      <div class="col-lg">
        <PaymentMethodBox
          class="box-custom dashboard-card"
          :dashboard-balance="dashboardBalance"
          :goals-balance="dashboardGoalsBalance"
        />
      </div>
      <div class="col-lg">
        <CreditCharts
          class="p-3 box-custom dashboard-card"
          :dashboard-balance="dashboardBalance"
          :goals-balance="dashboardGoalsBalance"
        />
      </div>

      <div class="row gap-3">
        <LastTransactions
          class="p-3"
          :transactions="dashboardBalance.data.lastTransactions"
        />
      </div>
    </div>
    <Modal
      :title="`Novo lançamento - ${getTransactionType(typeForm)}`"
      :is-open="isModalOpen"
      @close="closeModal"
    >
      <FormTransaction
        :type-form="typeForm"
        :quick="isQuick"
        @close="closeModal"
        @reload="getTransactions"
      />
    </Modal>
  </div>
</template>

<script>
// import WelcomeOverview from "@/components/shared/WelcomeOverview.vue";
import QuickBox from "@/components/transaction/QuickBox.vue";
import TransactionBox from "@/components/transaction/TransactionBox.vue";
import ButtonGradient from "@/components/shared/ButtonGradient.vue";
import InvestmentBox from "@/components/transaction/InvestmentBox.vue";
import CreditCharts from "@/components/transaction/CreditCharts.vue";
import Modal from "@/components/shared/Modal.vue";
import { useAppStore } from "@/store/store.js";
import FormTransaction from "@/components/transaction/FormTransaction.vue";
import LastTransactions from "@/components/transaction/LastTransactions.vue";
import GoalsBox from "@/components/financial_goal/GoalsBox.vue";
import PaymentMethodBox from "@/components/financial_goal/PaymentMethodBox.vue";

export default {
  components: {
    // WelcomeOverview,
    LastTransactions,
    QuickBox,
    TransactionBox,
    ButtonGradient,
    InvestmentBox,
    GoalsBox,
    PaymentMethodBox,
    FormTransaction,
    CreditCharts,
    Modal,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      goals: [],
      isQuick: true,
      isLoading: true,
      isModalOpen: false,
      investmentsAccounts: [],
      typeForm: "",
    };
  },
  computed: {
    dashboardBalance() {
      const store = useAppStore();
      const dashboard = store.dashboardBalance;
      return dashboard;
    },
    dashboardGoalsBalance() {
      const store = useAppStore();
      const dashboardGoalsBalance = store.dashboardGoalsBalance;
      return dashboardGoalsBalance;
    },
    dashboardGoalsBalance() {
      const store = useAppStore();
      const dashboardGoalsBalance = store.dashboardGoalsBalance;
      return dashboardGoalsBalance;
    },
    featureDisabled() {
      const store = useAppStore();
      return !store.hasCashFlowAccess()
    },
  },
  mounted: function () {
    this.loadData();
  },
  methods: {
    loadData() {
      const store = useAppStore();

      if (!store.dashboardBalance.loaded) {
        store.getDashboardBalance();
      }

      if (!store.dashboardGoalsBalance.loaded) {
        store.getDashboardGoalsBalance();
      }
    },
    openDetailForm() {
      if(!this.checkFeatureDisabled()){
        this.typeForm = "detail";
        this.isQuick = false;
        this.isModalOpen = true;
      }
    },
    goTo(route) {
      if(!this.checkFeatureDisabled()){
        this.$router.push(route);
        this.currentRoute = route;
      }
    },
    getTransactionType(valor) {
      const appStore = useAppStore();
      return appStore.getTransactionType(valor);
    },
    getTransactions() {
      const appStore = useAppStore();
      return appStore.getTransactions();
    },
    openModal(value, type) {
      if(!this.checkFeatureDisabled()){
        this.typeForm = type;
        this.isQuick = true;
        this.isModalOpen = true;
      }
    },
    closeModal() {
      if(!this.checkFeatureDisabled()){
        this.isModalOpen = false;
      }
    },
    showFeatureDisabledWarning() {
      this.$toast.add({
        severity: "warn",
        summary: "Recurso indisponível",
        detail: "Essa ferramenta não está incluída no plano contratado.",
        life: 5000,
      });
    },
    checkFeatureDisabled() {
      if (this.featureDisabled) {
        this.showFeatureDisabledWarning();
        return true
      }
      return false
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-card {
  height: 700; /* Adjust based on your requirements */
  overflow-y: auto; /* Enables vertical scrolling if content exceeds 300px */

  /* Styling the scrollbar */
  &::-webkit-scrollbar {
    width: 8px; /* Adjust the width of the scrollbar for a sleeker look */
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1); /* Subtle track background */
    border-radius: 4px; /* Optional: Rounds the corners of the scrollbar track */
  }

  &::-webkit-scrollbar-thumb {
    background: #181d2f; /* Your desired color for the scrollbar thumb */
    border-radius: 4px; /* Rounds the corners of the scrollbar thumb for a sleeker look */
    border: 2px solid rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle border to the thumb */
  }

  /* Optional: Change the scrollbar thumb color on hover for better user feedback */
  &::-webkit-scrollbar-thumb:hover {
    background: lighten(
      #181d2f,
      10%
    ); /* Lightens the thumb color on hover for contrast */
  }
}

/* For Firefox: Customizing the scrollbar appearance */
.dashboard-card {
  scrollbar-width: thin; /* "auto", "thin", or "none" */
  scrollbar-color: #181d2f rgba(0, 0, 0, 0.1); /* Thumb and track color */
}
.gradient {
  background-color: transparent;
  display: flex;
  padding: 0;
  flex-direction: column;
  gap: 20px;
}
.row {
  --bs-gutter-x: 0 !important;
  --bs-gutter-y: 0 !important;
}
.box-custom {
  padding: 10px;
}
.disabled {
  opacity: 0.4;
}
/* Estilos específicos do DashboardView */
</style>
