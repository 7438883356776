<template>
  <div :class="['sidebar', { 'sidebar-closed': isMenuClosed }]">
    <button class="toggle-menu" @click="toggleMenu">
      <i
        :class="[
          'fas',
          {
            'fa-chevron-right': isMenuClosed,
            'fa-chevron-left': !isMenuClosed,
          },
        ]"
      />
    </button>
    <div class="logo-div">
      <img
        class="logo-icon"
        src="~@/assets/logo-icon.png"
        alt="Logo do Dashboard"
      />
      <img
        v-if="!isMenuClosed"
        class="logo"
        src="~@/assets/logo.png"
        alt="Logo do Dashboard"
      />
    </div>
    <div class="separator" />
    <ul class="nav">
      <li
        class="nav-item"
        :class="{ active: $route.path === '/about' }"
        @click="goTo('/about')"
      >
        <a class="nav-link" href="#">
          <i class="fas fa-home" />
          <span class="menu-label">Página inicial</span>
        </a>
      </li>

      <li
        class="nav-item"
        :class="{ active: $route.path === '/' }"
        @click="goToPreInterview"
        v-if="!hasFinancialPlan"
      >
        <a class="nav-link" href="#">
          <i class="fas fa-crown" />
          <span class="menu-label">Diagnóstico gratuito</span>
        </a>
      </li>

      <li
        class="nav-item"
        :class="{ active: $route.path === '/' }"
        @click="goToFinancialPLanning"
        v-if="hasFinancialPlan"
      >
        <a class="nav-link" href="#">
          <i class="fas fa-crown" />
          <span class="menu-label">Meu plano financeiro</span>
        </a>
      </li>

      <span v-if="!isMenuClosed" class="user-tool">Ferramentas do Cliente</span>
      <li
        class="nav-item"
        :class="{ active: $route.path.includes('/cashflow') }"
        @click="goTo('/cashflow/dashboard')"
      >
        <a class="nav-link" href="#">
          <i class="fas fa-solid fa-dollar-sign" />
          <span class="menu-label">Gestão financeira</span>
        </a>
      </li>
      <li
        class="nav-item"
        :class="{ active: $route.path.includes('/planning') }"
        @click="goTo('/planning/future')"
      >
        <a class="nav-link" href="#">
          <i class="fas fa-regular fa-rectangle-list" />
          <span class="menu-label">Linha do futuro</span>
        </a>
      </li>
      <!--
      <li
        class="nav-item"
        :class="{ active: currentRoute === 'investments' }"
        @click="soon()"
      >
        <a class="nav-link" href="#">
          <i class="fas fa-solid fa-chart-column" />
          <span class="menu-label">Investimentos</span>
        </a>
      </li>
      <li
        class="nav-item"
        :class="{ active: currentRoute === 'planning' }"
        @click="soon()"
      >
        <a class="nav-link" href="#">
          <i class="fas fa-regular fa-rectangle-list" />
          <span class="menu-label">Planejamento</span>
        </a>
      </li>
      <li
        class="nav-item"
        :class="{ active: currentRoute === 'insurance' }"
        @click="soon()"
      >
        <a class="nav-link" href="#">
          <i class="fas fa-regular fa-rectangle-list" />
          <span class="menu-label">Seguros</span>
        </a>
      </li>
      -->
      <span v-if="!isMenuClosed" class="user-tool">Benefícios</span>
      <li
        class="nav-item"
        :class="{ disabled: featureDisabled }"
        @click.prevent="openBusinessSchoolLink()"
      >
        <a class="nav-link" href="#">
          <i class="fas fa-solid fa-graduation-cap" />
          <span class="menu-label"> Escola de negócios </span>
        </a>
      </li>
      <li
        class="nav-item"
        :class="{ disabled: featureDisabled }"
        @click.prevent="openBenefitsClubLink()"
      >
        <a class="nav-link" href="#">
          <i class="fas fa-regular fa-gem" />
          <span class="menu-label"> Clube de benefícios </span>
        </a>
      </li>
      <li class="nav-item" @click.prevent="logout()">
        <a class="nav-link" href="#">
          <i class="fas fa-door-open fa-regular" />
          <span class="menu-label"> Sair </span>
        </a>
      </li>
      <!--
      <li class="nav-item" @click="soon()">
        <a class="nav-link" href="#">
          <i class="fas fa-solid fa-bell" />

          <span class="menu-label"> Notificações </span>
        </a>
      </li>
      -->
    </ul>
  </div>
</template>

<script>
import { useAppStore } from "@/store/store.js";
export default {
  data() {
    return {
      isMenuClosed: false,
      currentRoute: "/cashflow/dashboard",
    };
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
  computed: {
    featureDisabled() {
      const store = useAppStore();
      return !store.hasAdditionalBenefits()
    },
    hasFinancialPlan() {
      const store = useAppStore();
      return store.financialPlanningLink !== null;
    }
  },
  methods: {
    toggleMenu() {
      this.isMenuClosed = !this.isMenuClosed;
      this.$emit("menu-toggle", this.isMenuClosed);
    },
    goTo(route) {
      this.$router.push(route);
      this.currentRoute = route;
    },
    async goToPreInterview(route) {
      const store = useAppStore();
      await store.goToPreInterview();
    },
    async goToFinancialPLanning(route) {
      const store = useAppStore();
      await store.goToFinancialPLanning();
    },
    checkScreenSize() {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 767) {
        // Defina o limite de largura para dispositivos móveis ou tablets
        this.isMenuClosed = true;
        this.$emit("menu-toggle", this.isMenuClosed);
      } else {
        this.isMenuClosed = false;
        this.$emit("menu-toggle", this.isMenuClosed);
      }
    },
    openBusinessSchoolLink() {
      if (this.featureDisabled) {
        this.$toast.add({
          severity: "warn",
          summary: "Recurso indisponível",
          detail: "Essa ferramenta não está incluída no plano contratado.",
          life: 5000,
        });
      } else if (!this.menuItemDisabled) {
        setTimeout(() => {
          window
            .open(process.env.VUE_APP_BUSINESS_SCHOOL_URL, "_blank")
            .focus();
        });
      }
    },
    openBenefitsClubLink() {
      if (this.featureDisabled) {
        this.$toast.add({
          severity: "warn",
          summary: "Recurso indisponível",
          detail: "Essa ferramenta não está incluída no plano contratado.",
          life: 5000,
        });
      } else if (!this.menuItemDisabled) {
        setTimeout(() => {
          window.open(process.env.VUE_APP_BENEFITS_CLUB_URL, "_blank").focus();
        });
      }
    },
    soon() {
      alert("Em breve!");
    },
    logout() {
      localStorage.removeItem("token");
      window.location.href = "/login";
    },
    checkFeatureDisabled() {
      if (this.featureDisabled) {
        this.$toast.add({
          severity: "warn",
          summary: "Recurso indisponível",
          detail: "Essa ferramenta não está incluída em seu plano contratado.",
          life: 5000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  padding: 0;
}
.logo-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-tool {
  text-transform: uppercase;
  text-align: left;
  font-size: 13px;
  letter-spacing: 0.6px;
  color: #676d7d;
  opacity: 1;
  margin: 20px 0 8px 0;
}

ul {
  list-style-type: none;
  padding-left: 0;
  li {
    margin: 2px 0;
    width: 100%;
  }
}
.nav-link {
  display: flex;
  align-items: center;
  color: #fff !important;
  padding: 8px;
  border: none;
  font-size: 15px;
  i {
    color: #ffffff;
    margin-right: 10px;
  }

  span:hover {
    font-weight: bold;
  }
}

.nav-link:hover {
  color: #ffffff;
}
.logo {
  width: 150px;
  margin: 0 !important ;
}

.dashboard {
  display: flex;
  height: 100vh;
}

.active {
  background: #cb5540 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #f26236ad;
  border-radius: 4px;
  opacity: 0.97;
}

.sidebar {
  width: 250px;
  background-color: #283045;
  padding: 20px;
  position: absolute;
  top: 0;
  z-index: 900;
  left: 0;
  bottom: 0;
}

.sidebar-closed {
  width: 75px;
  i {
    padding: 10px 0;
  }
}
ul {
  list-style-type: none;
  padding-left: 0;
  text-align: left;
  margin: 0;
}

.nav-item {
  transition: padding-left 0.3s ease;
}

.sidebar-closed .nav-item {
  padding-left: 0;
}

.toggle-menu {
  position: absolute;
  bottom: 20px;
  left: 10px;
  padding: 5px;
  color: #fff;
  background-color: #f1f1f100;
  border: none;
  cursor: pointer;
}

.fa-chevron-left,
.fa-chevron-right {
  transition: transform 0.3s ease;
}

.sidebar-closed .fa-chevron-left {
  transform: rotate(180deg);
}

.sidebar-closed .fa-chevron-right {
  transform: rotate(0deg);
}

.menu-label {
  display: inline-block;
  margin-left: 10px;
}

.sidebar-closed .menu-label {
  display: none;
}

.disabled {
  opacity: 0.4;
}
</style>
