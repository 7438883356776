import { defineStore } from "pinia";
import axios from "axios";
import router from "@/router";
import { DateTime } from "luxon";

export const useAppStore = defineStore("app", {
  state: () => ({
    transactionMethodResponse: null,
    transactionTypeResponse: null,
    categoriesResponse: null,
    financialPlanningLink: null,

    profileImage: null,

    dashboardBalance: {
      data: null,
      loading: false,
      loaded: false,
    },

    dashboardTotal: {
      data: null,
      loading: false,
      loaded: false,
    },

    dashboardGoalsBalance: {
      data: null,
      loading: false,
      loaded: false,
    },

    goalsBalance: {
      data: null,
      loading: false,
      loaded: false,
    },

    historicBalance: {
      data: null,
      loading: false,
      loaded: false,
    },

    auth: false,

    icons: [
      "fas fa-shopping-cart",
      "fas fa-utensils",
      "fas fa-home",
      "fas fa-car",
      "fas fa-book",
      "fas fa-medkit",
      "fas fa-money-bill",
      "fas fa-plane",
      "fas fa-laptop",
      "fas fa-gamepad",
      "fas fa-tshirt",
      "fas fa-briefcase",
      "fas fa-heart",
      "fas fa-graduation-cap",
      "fas fa-camera",
      "fas fa-music",
      "fas fa-paint-brush",
      "fas fa-birthday-cake",
      "fas fa-tools",
      "fas fa-tree",
      "fas fa-lightbulb",
    ],
    colors: [
      "#FF5733",
      "#37BC9B",
      "#3498DB",
      "#F39C12",
      "#9B59B6",
      "#E74C3C",
      "#1ABC9C",
      "#2980B9",
      "#F1C40F",
      "#8E44AD",
    ],

    userResponse: {
      data: null,
      loading: true,
      loaded: false,
    },

    userAge: 20,

    balanceCategoriesResponse: null,
    balanceMethodsResponse: null,
    accountsResponse: null,
    typeGoalsResponse: null,
    currentBalanceByAccountResponse: null,
    balanceByAccountResponse: null,
    balanceByCategoryResponse: null,
    balanceByMethodResponse: null,
    balanceByAccountResponse2: null,
    balanceByMonth: null,
    goalByMonth: null,
    investmentAccounts: null,
    token: null,
    categoryGoalsResponse: null,
    financialGoalsResponse: null,
    categoryFinancialGoalsResponse: null,
    methodFinancialGoalsResponse: null,
    bankResponse: null,
    accountTypeResponse: null,
    transactionResponse: null,
    loadingBalanceGoals: true,
    loadingTransactions: true,
    reload: false,
    exchangeRates: null,
    isBrlToUsd: false,
    isBrlToEur: false,
    hideCurrency: true,
    hasFreemiumAccess: false,
    freemiumRemainingDays: 0 
  }),

  getters: {
    formatValue: (state) => (value) => {
      if (!value) {
        value = 0;
      }
      let numericValue = value;
      if (typeof value === "string") {
        // Remove commas before parsing
        const sanitizedValue = value.replace(/,/g, "");
        numericValue = parseFloat(sanitizedValue);
      }

      if (state.isBrlToUsd) {
        const options = {
          style: "currency",
          currency: "USD",
        };

        // const value = valor / this.dollarValue;

        return new Intl.NumberFormat("pt-BR", options).format(value);
      }

      if (state.isBrlToEur) {
        const options = {
          style: "currency",
          currency: "EUR",
        };

        // const value = valor / this.euroValue;

        return new Intl.NumberFormat("pt-BR", options).format(value);
      }

      return numericValue.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
    getFormattedDate: () => (dateString) => {
      const date = DateTime.fromISO(dateString).toUTC();
      return date.toFormat("dd/MM/yyyy");
    },

    getTransactionType: () => (type) => {
      switch (type) {
        case "debit":
          return "Despesa";
        case "credit":
          return "Receita";
        case "investment":
          return "Investimento";
        case "investment_withdrawal":
          return "Resgate";
        default:
          return "";
      }
    },
    getReloadStatus(state) {
      return state.reload;
    },
    getTotalCurrentDebit: (state) => {
      if (!state.currentBalanceByAccountResponse) {
        return 0;
      }

      return state.currentBalanceByAccountResponse.reduce(
        (totalDebit, item) => {
          totalDebit += parseFloat(item.debit);
          return totalDebit;
        },
        0
      );
    },
    getTotalCurrentCredit: (state) => {
      if (!state.currentBalanceByAccountResponse) {
        return 0; // Return 0 if the balanceByAccountResponse is not available yet
      }

      return state.currentBalanceByAccountResponse.reduce(
        (totalCredit, item) => {
          totalCredit += parseFloat(item.credit);
          return totalCredit;
        },
        0
      );
    },
    getInvestmentAccounts: (state) => {
      let accounts = null;
      if (state.accountsResponse != null) {
        accounts = state.accountsResponse.filter(
          (account) => account.id_account_type === 3
        );
      }

      return accounts;
    },
    getInvestmentBalance: (state) => {
      let balance = 0;
      if (
        state.getInvestmentAccounts != null &&
        state.balanceByAccountResponse2 != null
      ) {
        const filteredIds = state.getInvestmentAccounts.map(
          (account) => account.id
        );

        balance = state.balanceByAccountResponse2.filter((item) =>
          filteredIds.includes(item.id)
        );
      }

      return balance;
    },

    getExchangeRates: (state) => state.exchangeRates,
    getisBrlToUsd: (state) => state.isBrlToUsd,
  },

  actions: {
    async goToPreInterview() {
      const token = localStorage.getItem("token");
      this.token = token;
      try {
        const response = await axios.get(`/user/interview_token`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const { authentication_token, customer_token } = response.data;

        // Construct the URL
        const baseUrl = process.env.VUE_APP_INTERVIEW_FRONTEND_URL;
        const authUrl = `${baseUrl}/autenticacao_cliente/${authentication_token}?back_url=pre_entrevista/${customer_token}`;

        // Open the URL in a new tab
        window.open(authUrl, "_blank");
      } catch (error) {
        console.error(error);
      }
    },
    async goToFinancialPLanning() {
      try {
        window.open(this.financialPlanningLink, "_blank");
      } catch (error) {
        console.error(error);
      }
    },
    getToken() {
      const token = localStorage.getItem("token");
      this.token = token;
      if (!token) {
        this.auth = false;
        router.push("/login");
      } else {
        this.auth = true;
      }
      return token;
    },

    // Initial Objects

    async getUser() {
      const token = this.getToken();
      this.userResponse.loading = true;
      try {
        const userFastapp = await this.getUserData(token);
        const userInfoResponse = await this.getUserInfoData(userFastapp.cpf);
        this.userResponse.data = {
          userFastapp: userFastapp,
          userInfo: userInfoResponse.customer,
          userConsultant: userInfoResponse.consultant,
          userGoals: userInfoResponse.objectives,
        };
        this.profileImage = userInfoResponse.customer.profile_picture_url;

        const birthDate = DateTime.fromISO(
          userInfoResponse.customer.birth_date
        );
        const age = DateTime.local().diff(birthDate, "years").years;
        this.userAge = parseInt(age);

        if (userFastapp?.currency_type == "usd") {
          this.isBrlToUsd = true;
          this.isBrlToEur = false;
        } else if (userFastapp?.currency_type == "eur") {
          this.isBrlToEur = true;
          this.isBrlToUsd = false;
        } else {
          this.isBrlToEur = false;
          this.isBrlToUsd = false;
        }

        this.userResponse.loading = false;
        localStorage.setItem(
          "user_credentials",
          JSON.stringify(this.userResponse.data)
        );
        if(userFastapp.freemium_remaining_time > 0) {
          this.hasFreemiumAccess = true
          this.freemiumRemainingTime = userFastapp.freemium_remaining_time
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          window.location.href = "/login";
          console.log(error.response);
        } else {
          console.error(error);
        }
      }
    },

    async getUserData(token) {
      try {
        const response = await axios.get(`/user`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        this.financialPlanningLink = response.data.financialPlanningLink;
        return response.data.user;
      } catch (error) {
        throw error;
      }
    },

    async getUserInfoData(cpf) {
      const token = this.getToken();

      try {
        const response = await axios.get(`/checkCpf/${cpf}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        return response.data;
      } catch (error) {
        throw error;
      }
    },

    getInitialObjects() {
      this.getAccountType();
      this.getBank();
      this.getCategories();
      this.getAccounts();
      this.getMethods();
      this.getTransactionTypes();
    },

    getCategories() {
      const token = this.getToken();

      axios
        .get(`/transaction_category?$limit=1000`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          this.categoriesResponse = response.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getMethods() {
      const token = this.getToken();

      axios
        .get(`/transaction_method`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          this.transactionMethodResponse = response.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getTransactionTypes() {
      const token = this.getToken();

      axios
        .get(`/transaction_type`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          this.transactionTypeResponse = response.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async getAccounts() {
      const token = this.getToken();

      try {
        const response = await axios.get(`/bank_account`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        this.accountsResponse = response.data.data;

        // Chame a função para buscar os saldos
        await this.getBalanceByAccount();

        // Relacionar os saldos com as contas
        this.accountsResponse.forEach((account) => {
          if (account.id_account_type == 1 || account.id_account_type == 2) {
            const matchingBalance = this.balanceByAccountResponse.find(
              (balance) => balance.id === account.id
            );
            if (matchingBalance) {
              account.balance = matchingBalance.balance;
            }
            const matchingType = this.accountTypeResponse.find(
              (acc) => acc.id === account.id
            );
            if (matchingType) {
              account.label = matchingType.label;
            }
          }
        });
      } catch (error) {
        console.error(error);
      }
    },

    getAccountType() {
      const token = this.getToken();

      axios
        .get(`/account_type`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          this.accountTypeResponse = response.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getBank() {
      const token = this.getToken();

      axios
        .get(`/bank`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          this.bankResponse = response.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    // Dashboard
    getDashboardBalance() {
      this.dashboardBalance.loading = true;
      const token = this.getToken();

      axios
        .get(`/dashboard_balance`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          this.dashboardBalance.data = response.data;
          this.dashboardBalance.loaded = true;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.dashboardBalance.loading = false;
        });
    },

    // Dashboard Total
    getDashboardTotal(params = {}) {
      this.dashboardTotal.loading = true;
      const token = this.getToken();
      const config = {
        method: "get",
        url: `/dashboard_total`,
        headers: { Authorization: `Bearer ${token}` },
        params,
      };

      return axios(config)
        .then((response) => {
          this.dashboardTotal.data = response.data;
          this.dashboardTotal.loaded = true;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.dashboardTotal.loading = false;
        });
    },

    // Dashboard Goals Balance
    getDashboardGoalsBalance(params = {}) {
      this.dashboardGoalsBalance.loading = true;
      const token = this.getToken();
      const config = {
        method: "get",
        url: `/goals_balance`,
        headers: { Authorization: `Bearer ${token}` },
        params,
      };
      return axios(config)
        .then((response) => {
          this.dashboardGoalsBalance.data = response.data;
          this.dashboardGoalsBalance.loaded = true;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.dashboardGoalsBalance.loading = false;
        });
    },

    // Goals Balance
    getGoalsBalance(params = {}) {
      this.goalsBalance.loading = true;
      const token = this.getToken();
      const config = {
        method: "get",
        url: `/goals_balance`,
        headers: { Authorization: `Bearer ${token}` },
        params,
      };
      return axios(config)
        .then((response) => {
          this.goalsBalance.data = response.data;
          this.goalsBalance.loaded = true;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.goalsBalance.loading = false;
        });
    },

    // Historic Balance
    getHistoricBalance(params = {}) {
      this.historicBalance.loading = true;
      const token = this.getToken();
      const config = {
        method: "get",
        url: `/historic_by_year`,
        headers: { Authorization: `Bearer ${token}` },
        params,
      };

      return axios(config)
        .then((response) => {
          this.historicBalance.data = response.data;
          this.historicBalance.loaded = true;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.historicBalance.loading = false;
        });
    },

    // Transactions

    async getTransactions(month = null, year = null) {
      this.loadingTransactions = true;

      const token = this.getToken();

      let startDate, endDate;

      if (month !== null && year !== null) {
        startDate = `${year}-${month.toString().padStart(2, "0")}-01`;
        endDate = `${year}-${month.toString().padStart(2, "0")}-31`;
      } else {
        // Obter o mês e o ano atuais
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // Os meses em JavaScript começam em 0, por isso, adicionamos 1.

        startDate = `${currentYear}-${currentMonth
          .toString()
          .padStart(2, "0")}-01`;
        endDate = `${currentYear}-${currentMonth
          .toString()
          .padStart(2, "0")}-31`;
      }

      try {
        const response = await axios.get(`/transaction`, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            $sort: { transaction_at: -1 },
            $limit: 1000,
            "transaction_at[$gte]": startDate,
            "transaction_at[$lte]": endDate,
          },
        });
        this.transactionResponse = response.data.data;
        this.loadingTransactions = false;
      } catch (error) {
        console.log(error);
      }
    },

    //update Data

    updateData() {
      this.getGoalsBalance();
      this.getDashboardBalance();
      this.getAccounts();
      this.getDashboardTotal();
      this.getDashboardGoalsBalance();
      this.getHistoricBalance();
    },

    /// Não Revisado

    getFinancialGoals() {
      const token = this.getToken();

      axios
        .get(`/financial_goal?$limit=1000`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          this.financialGoalsResponse = response.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async getFinancialGoals(month, year) {
      this.loadingBalanceGoals = true;
      const token = this.getToken();
      try {
        const requests = [
          axios.get(`/balance_by_category?$limit=1000`, {
            headers: { Authorization: `Bearer ${token}` },
            params: {
              month: month,
              year: year,
            },
          }),
          axios.get(`/balance_by_method?$limit=1000`, {
            headers: { Authorization: `Bearer ${token}` },
            params: {
              month: month,
              year: year,
            },
          }),
          axios.get(`/balance`, {
            headers: { Authorization: `Bearer ${token}` },
            params: {
              month: month,
              year: year,
            },
          }),
          axios.get(`/total_goal`, {
            headers: { Authorization: `Bearer ${token}` },
            params: {
              month: month,
              year: year,
            },
          }),
          axios.get(`/category_balance`, {
            headers: { Authorization: `Bearer ${token}` },
            params: {
              month: month,
              year: year,
            },
          }),
          axios.get(`/method_balance`, {
            headers: { Authorization: `Bearer ${token}` },
            params: {
              month: month,
              year: year,
            },
          }),
        ];

        const [
          response1,
          response2,
          response3,
          response4,
          response5,
          response6,
        ] = await Promise.all(requests);

        this.categoryFinancialGoalsResponse = response1.data;
        this.methodFinancialGoalsResponse = response2.data;
        this.balanceByMonth = response3.data[0];
        this.goalByMonth = response4.data[0];
        this.balanceCategoriesResponse = response5.data;
        this.balanceMethodsResponse = response6.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingBalanceGoals = false;
      }
    },

    async getTypeGoals() {
      const token = this.getToken();

      try {
        const response = await axios.get(`/goal_by_type`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        this.typeGoalsResponse = response.data;
      } catch (error) {
        console.error(error);
      }
    },

    async getCategoryGoals() {
      const token = this.getToken();

      try {
        const response = await axios.get(`/goal_by_category`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        this.categoryGoalsResponse = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getCurrentBalanceByAccount(month, year) {
      const token = this.getToken();

      try {
        const response = await axios.get(
          `/balance_by_bank_account2?month=${month}&year=${year}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        this.currentBalanceByAccountResponse = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getBalanceByAccount2() {
      const token = this.getToken();

      try {
        const response = await axios.get(`/balance_by_bank_account2`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        this.balanceByAccountResponse2 = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getBalanceByAccount() {
      const token = this.getToken();

      try {
        const response = await axios.get(`/balance_by_bank_account`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        this.balanceByAccountResponse = response.data;
      } catch (error) {
        console.error(error);
      }
    },

    toggleReload() {
      this.reload = !this.reload;
    },

    hasCashFlowAccess() {
      let user = this.userResponse?.data?.userInfo;
      if (!user) {
        const user_credentials = localStorage.getItem("user_credentials");
        user = JSON.parse(user_credentials)?.userInfo;
      }
      return (
        user?.financial_management_active ||
        user?.is_pn_employee ||
        user?.other_running_products || 
        this.hasFreemiumAccess
      );
    },

    hasPlanningAccess() {
      const user = this.userResponse?.data?.userInfo;
      return ( 
        !user?.financial_management_active &&
        (user?.is_pn_employee || user?.other_running_products)
      ) || this.hasFreemiumAccess;
    },

    hasAdditionalBenefits() {
      let user = this.userResponse?.data?.userInfo;
      if (!user) {
        const user_credentials = localStorage.getItem("user_credentials");
        user = JSON.parse(user_credentials)?.userInfo;
      }
      return (
        user?.financial_management_active ||
        user?.is_pn_employee ||
        user?.other_running_products
      );
    },

    // API PARA CASO FUTURAMENTE PRECISE PEGAR VALORES DE DÓLAR E EURO EM TEMPO REAL

    // async fetchExchangeRates() {
    //   try {
    //     const response = await axios.get(
    //       "https://economia.awesomeapi.com.br/json/last/USD-BRL,EUR-BRL"
    //     );
    //     const rates = response.data;
    //     this.exchangeRates = rates;
    //   } catch (error) {
    //     this.isBrlToUsd = false;
    //     this.isBrlToEur = false;
    //     throw error;
    //   }
    // },

    async changeRate(value) {
      // const dollar = this.exchangeRates?.USDBRL?.high;
      // const euro = this.exchangeRates?.EURBRL?.high;

      if (value === "usd") {
        // await this.fetchExchangeRates();
        this.isBrlToUsd = true;
        this.isBrlToEur = false;
      }

      if (value === "eur") {
        // await this.fetchExchangeRates();
        this.isBrlToEur = true;
        this.isBrlToUsd = false;
      }

      if (value === "brl") {
        this.isBrlToUsd = false;
        this.isBrlToEur = false;
      }
      this.updateCurrencyType(value);
    },

    updateCurrencyType(currency_type) {
      axios
        .put(
          `/user/currency_type`,
          { currency_type },
          {
            headers: { Authorization: `Bearer ${this.token}` },
          }
        )
        .catch((error) => {
          console.error(error);
        });
    },
    showCurrency() {
      this.hideCurrency = !this.hideCurrency;
    },
  },
});
