import { createRouter, createWebHistory } from "vue-router";

import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView.vue";
import TokenAuthView from "../views/TokenAuthView.vue";
import RegisterView from "../views/RegisterView.vue";
import ForgotPasswordView from "../views/ForgotPasswordView.vue";
import ResetPasswordView from "../views/ResetPasswordView.vue";
import AboutView from "../views/AboutView.vue";
import DashboardView from "../views/CashFlow/DashboardView.vue";
import GoalsView from "../views/CashFlow/GoalsView.vue";
import TransactionView from "../views/CashFlow/TransactionView.vue";
import HistoricView from "../views/CashFlow/HistoricView.vue";
import CashFlowView from "../views/CashFlow/CashFlowView.vue";
import InvestmentsView from "../views/InvestmentsView.vue"; // New view
import PlanningView from "../views/Planning/PlanningView.vue"; // New view
import InsuranceView from "../views/InsuranceView.vue"; // New view
import SettingsView from "../views/CashFlow/SettingsView.vue"; // New view
import FreemiumDashboard from "../views/FreemiumDashboard.vue";
import { useAppStore } from "../store/store";


const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/login",
      name: "login",
      component: LoginView,
    },
    {
      path: "/autenticacao/:token",
      name: "authToken",
      component: TokenAuthView,
    },
    {
      path: "/forgot-password",
      name: "forgotPassword",
      component: ForgotPasswordView,
    },
    {
      path: "/register",
      name: "register",
      component: RegisterView,
    },
    {
      path: "/seja-futuro/:consultant/:alias",
      name: "registerConsultant",
      component: RegisterView,
    },
    {
      path: "/reset-password",
      name: "resetPassword",
      component: ResetPasswordView,
    },
    {
      path: "/",
      name: "home",
      component: HomeView,
      children: [
        {
          path: "",
          name: "home-default",
          redirect: "/about",
        },
        {
          path: "/about",
          name: "about",
          component: AboutView,
        },
        {
          path: "/cashflow",
          name: "cashflow",
          component: CashFlowView,
          children: [
            {
              path: "",
              name: "cashflow-default",
              redirect: "cashflow/dashboard",
            },
            {
              path: "dashboard",
              name: "dashboard",
              component: DashboardView,
            },
            {
              path: "goals",
              name: "goals",
              component: GoalsView,
            },
            {
              path: "transactions",
              name: "transactions",
              component: TransactionView,
            },
            {
              path: "historic",
              name: "historic",
              component: HistoricView,
            },
            {
              path: "settings",
              name: "settings",
              component: SettingsView,
            },
          ],
        },
        {
          path: "/planning",
          name: "planning",
          component: PlanningView,
          children: [
            {
              path: "future",
              name: "future",
              component: () => import("../views/Planning/FutureView.vue"),
            },
            {
              path: "retirement",
              name: "retirement",
              component: () => import("../views/Planning/RetirementView.vue"),
            },
            {
              path: "dreams",
              name: "dreams",
              component: () => import("../views/Planning/DreamsView.vue"),
            },
          ],
        },
        {
          path: "/investments",
          name: "investments",
          component: InvestmentsView,
        },
        {
          path: "/insurance",
          name: "insurance",
          component: InsuranceView,
        },
      ],
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
});

const publicRoutes = [
  "login",
  "register",
  "registerConsultant",
  "forgotPassword",
  "resetPassword",
  "authToken",
];

const freemiumRoutes = ["home", "dashboard", "cashflow-default", "future", "about", ...publicRoutes]

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  if (!token && !publicRoutes.includes(to.name)) {
    next({ name: "login" });
  } else {
    const store = useAppStore();
    if(!freemiumRoutes.includes(to.name)
      && !store.hasCashFlowAccess()
      && !store.hasPlanningAccess()
    ){
      return next({ name: "dashboard" });
    }
    next();
  }
});

export default router;
